
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ILink } from '../../shared/general/interfaces/ILink'
import BaseLink from './BaseLink.vue'

@Component({
  name: 'BaseTeaserHome',
  components: {
    BaseLink,
    BaseIcon: () => import('./BaseIcon.vue'),
  },
})
export default class BaseTeaserHome extends Vue {
  @Prop() date ?: string

  @Prop() location ?: string

  @Prop() headline ?: string

  @Prop() text ?: string

  @Prop({ required: true }) link! : ILink

  @Prop({ default: false }) isDark! : boolean
}
